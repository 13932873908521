*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  @apply scrollbar-thin scrollbar-track-superDark scrollbar-thumb-purple;
  overflow: unset; /*apply nicer scrollbar but do not override any overflow property*/
}

*::-webkit-scrollbar-track {
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #43339a;
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  @apply text-base;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

select > option {
  background-color: #080723;
}

select > option:checked {
  background-color: #43339a;
}

.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

#ot-sdk-cookie-policy {
  #cookie-policy-title {
    color: white !important;
  }
  #cookie-policy-description {
    color: white !important;
  }
  .ot-sdk-cookie-policy-group {
    color: white !important;
  }
  .ot-sdk-cookie-policy-group-desc {
    color: white !important;
  }
  table {
    border: 1px solid rgb(97 22 227 / var(--tw-bg-opacity)) !important;
  }

  tr > th {
    border-color: white !important;
    background-color: rgb(97 22 227 / var(--tw-bg-opacity)) !important;
    color: white !important;
  }
  tr > td {
    color: white !important;
    > span {
      color: white !important;
    }
    > a {
      background: transparent !important;
      color: rgb(0 201 254 / var(--tw-text-opacity));
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.background-image {
  position: relative;
  background-color: #090138;
}
.background-image::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/assets/under-construction.jpg'); /* Set your image URL here */
  background-size: cover; /* Cover the entire area of the element */
  opacity: 0.15; /* Set the opacity of the image */
  pointer-events: none;
  background-position: center;
}
