@tailwind base;
@import './base.css';

@layer base {
  html {
    font-family: 'Lato';
    scroll-behavior: smooth;
  }
}

@tailwind components;
@import './components.css';

@tailwind utilities;
