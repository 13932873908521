@tailwind components;

@layer components {
  .gradient-background {
    @apply bg-dark;
    @apply before:absolute before:z-0 before:right-0 before:bottom-0 before:top-0 before:left-0 before:absolute;
    @apply before:bg-backgroundGradient before:bg-repeat-y before:bg-[length:130vw_130vw] before:bg-[position:-60vw_70vw] before:opacity-40;
    @apply after:absolute after:z-0 after:right-0 after:bottom-0 after:top-0 after:left-0 after:absolute after:bg-backgroundGradient;
    @apply after:bg-repeat-y after:bg-[length:130vw_130vw] after:bg-[position:30vw_20vw] after:opacity-40;
  }
  .gradient-background-10 {
    @apply bg-dark;
    @apply before:absolute before:z-10 before:right-0 before:bottom-0 before:top-0 before:left-0 before:absolute;
    @apply before:bg-backgroundGradient before:bg-repeat-y before:bg-[length:130vw_130vw] before:bg-[position:-60vw_70vw] before:opacity-40;
    @apply after:absolute after:z-10 after:right-0 after:bottom-0 after:top-0 after:left-0 after:absolute after:bg-backgroundGradient;
    @apply after:bg-repeat-y after:bg-[length:130vw_130vw] after:bg-[position:30vw_20vw] after:opacity-40;
  }
  .border-fade {
    -webkit-mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 16%, rgba(0,0,0,1) 84%, rgba(0,0,0,0) 100%);
  }
  .narrow-border-fade {
    -webkit-mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%);
  }
}
